import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { getUserDetails, saveProfileImage } from "services";
import { getSecondaryProfile, setSecondaryProfile } from "utilities";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import Spinner from "react-bootstrap/Spinner";

const storage = getStorage();

export const HomeProfileSection = ({ userData }) => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    uploadImage(selectedFile);
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadImage = (selectedFile) => {
    if (selectedFile) {
      const fileName = `${new Date().getTime()}_${selectedFile.name}`;
      const imageRef = ref(storage, fileName);

      const uploadTask = uploadBytesResumable(imageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading image:", error.message);
          setUploadStatus("Upload failed");
        },
        async () => {
          // Upload completed successfully, get the download URL
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          saveProfileImage(downloadURL);
          userData.avatar = downloadURL;
          let localData = getSecondaryProfile();
          localData.avatar = downloadURL;
          setSecondaryProfile(localData);

          setUploadProgress(0);
          setUploadStatus("Upload successful. Image URL: " + downloadURL);
        }
      );
    } else {
      alert("Please select an image");
    }
  };

  return (
    <div className="profileSectionContainer">
      <div className="imageContainer">
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {uploadProgress > 0 ? (
          <Spinner animation="grow" size="sm">
            <span className="visually-hidden">{`${uploadProgress}%`}</span>
          </Spinner>
        ) : (
          <img
            style={{
              width: 140,
              height: 140,
              borderRadius: 140,
              cursor: "pointer",
            }}
            src={
              userData.avatar ||
              "https://fafen.org/wp-content/uploads/2023/01/dummy.jpg"
            }
            onClick={() => {
              openFilePicker();
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: 25,
        }}
      >
        <span>
          Member since
          <span style={{ fontWeight: "700" }}>{` ${
            userData?.memberSince?.split("-")[0] || "- - - -"
          }`}</span>
        </span>

        <span style={{ fontSize: 25, fontWeight: "bold" }}>{`${
          userData.firstName || ""
        } ${userData.lastName || "- - - -   - - - - - - "}`}</span>
      </div>
    </div>
  );
};
