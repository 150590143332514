import React from "react";
import "./styles.scss";
import { CircularProgress } from "@mui/material";

export const InfoCard = ({
  payable,
  userData,
  onOrderPress,
  onReOrderPress,
  loading,
}) => {
  function formatDateString(dateString) {
    if (dateString) {
      const [day, month, year] = dateString.split('/');
      const formattedDate = new Date(`${year}-${month}-${day}`);

      if (isNaN(formattedDate)) {
        throw new Error('Invalid date format');
      }

      return formattedDate.toLocaleDateString();
    }
    return ""
  }

  return (
    <>
      {payable ? (
        <div className="payableCardContainer">
          <div className="payableTopContainer">
            <span className="payableTitle">{"Lost card"}</span>
            <span className="payableSubTitle">
              {userData?.cardReOrderStatus == ""
                ? "Enjoy all the features of your World Medical Card from your wallet"
                : "If you have lost or wish to receive an extra card then click Reorder"}
            </span>
          </div>
          <div className="purchaseSectionContainer">
            <div className="purchaseSection">
              <span className="purchasePrice">{`€ 4.99`}</span>
              <button
                className="purchaseButton"
                disabled={userData?.cardOrderStatus == "" ? true : false}
                onClick={onReOrderPress}
              >
                {loading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  <span>{`Reorder`}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : userData?.cardOrderStatus == "" ? (
        <div className="payableCardContainer">
          <div className="payableTopContainer">
            <span className="payableTitle">{"Order Your Card"}</span>
            <span className="payableSubTitle">
              {
                "Enjoy all the features of your World Medical Card from your wallet"
              }
            </span>
          </div>
          <div className="purchaseSectionContainer">
            <div className="purchaseSection">
              <span className="purchasePrice">{"Free"}</span>
              <button className="purchaseButton" onClick={onOrderPress}>
                {loading ? (
                  <CircularProgress size={18} color={"inherit"} />
                ) : (
                  <span>{`Order Now`}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="infoCardContainer">
          <span className="infoTitle">{`Medical Information`}</span>
          <div className="infoSectionRow">
            <span className="infoLabel">{`Name`}</span>
            <span className="infoValue">{`${userData.firstName} ${userData.lastName}`}</span>
          </div>
          <div className="infoSectionRow">
            <span className="infoLabel">Id No.</span>
            <span className="infoValue">{`${userData.ssn}`}</span>
          </div>
          <div className="infoSectionRow">
            <span className="infoLabel">DOB</span>
            <span className="infoValue">{userData.birthDate
            }</span> 
          </div>
          <div className="infoSectionRow">
            <span className="infoLabel">Issued</span>
            <span className="infoValue">{formatDateString(userData?.cardOrderDate)}</span>
          </div>
        </div>
      )}
    </>
  );
};
