import { preferenceKeys } from "common/preferenceKeys";

export const setProfile = (userDetails) => {
  localStorage.setItem(preferenceKeys.userProfile, JSON.stringify(userDetails));
};
export const setSecondaryProfile = userDetails => {
    localStorage.setItem(preferenceKeys.secondaryProfile, JSON.stringify(userDetails));
};

export const setToken = (authToken) => {
  localStorage.setItem(preferenceKeys.authToken, authToken);
};
export const setDeviceToken_ = (token) => {
  localStorage.setItem(preferenceKeys.deviceToken, token);
};
export const getDeviceToken_ = () => {
  return localStorage.getItem(preferenceKeys.deviceToken) || "";
};

export const getToken = () => {
  return localStorage.getItem(preferenceKeys.authToken) || "";
};

export const getProfile = () => {
  return JSON.parse(localStorage.getItem(preferenceKeys.userProfile)) || {};
};

export const setTempUid = (tempId) => {
  localStorage.setItem(preferenceKeys.tempId, tempId);
};

export const getTempUid = () => {
  return JSON.parse(localStorage.getItem(preferenceKeys.tempId) || {});
};

export const setTempUserDetail = (userDetails) => {
  localStorage.setItem(
    preferenceKeys.tempUserData,
    JSON.stringify(userDetails)
  );
};

export const getTempUserDetail = () => {
  return JSON.parse(localStorage.getItem(preferenceKeys.tempUserData) || {});
};
export const getSecondaryProfile = () => {
    return JSON.parse(localStorage.getItem(preferenceKeys.secondaryProfile)) || {};
};

export const clearStorage = () => {
  localStorage.clear();
};
