import React, { useEffect, useState } from "react";
import "./style.scss";
import { useNavigate } from "react-router";
import { routePaths } from "routes";
import { clearStorage } from "utilities";
import { images } from "common/images";
const { wmcLogo } = images;

export const Navigation = () => {
    let navigate = useNavigate();
    const [open, setOpen] = useState(false);
    useEffect(()=> {
        if(open) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'visible';
        }
    },[open])
    return (
        <>
        <div className="hambergerIco" onClick={()=> setOpen(val => !val)}>
        <img src={wmcLogo} height="auto" width="90px" className="nav-logo" />
         <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
            <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
            <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
            <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
          </svg>
         </div>
        <div className={`navigation ${open ? 'open' : 'closed'}`}>
            <div className="menu-items">
                <div>
                    <img src={wmcLogo} height="auto" width="100" alt="no-image" style={{ borderRadius: 0 }} />
                </div>
                <div className="other-links">
                    <span
                        onClick={() => {
                            window.location = 'https://worldmedicalcard.io/business/';
                        }}
                    >Business</span>
                    <span
                        onClick={() => {
                            window.location = 'https://worldmedicalcard.io/health-professionals/';
                        }}
                    >Health professionals</span>
                    <span
                        onClick={() => {
                            window.location = 'https://worldmedicalcard.io/pricing/';
                        }}
                    >Pricing</span>
                    <span
                        onClick={() => {
                            window.location = 'https://worldmedicalcard.io/about/';
                        }}
                    >About</span>
                    <span
                        onClick={() => {
                            window.location = 'https://worldmedicalcard.io/contact/';
                        }}
                    >Contact</span>
                    <span
                        onClick={() => {
                            navigate({
                                pathname: routePaths.signIn,
                            });
                            setOpen(false);
                        }}
                    >Login</span>
                    <span
                        onClick={() => {
                            navigate({
                                pathname: routePaths.signUp,
                            });
                            setOpen(false);
                        }}
                    >Sign up</span>
                    <span className="generic-btn"
                        onClick={() => {
                            window.location = 'https://emergency.worldmedicalcard.io/';
                        }}
                    >
                        Emergency
                    </span>
                </div>
            </div>
        </div>
        </>
    );
};
