import React, { useContext, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './styles.scss'
import { ApplicationContext } from "shared";
import { CATEGORIES_TYPES } from "common";
import { addNewItem } from "services";
import { CircularProgress } from "@mui/material";
import { getAllProfileRecords } from "services/firebaseServices";

export const AddItemPopup = ({
    showAdd,
    setShowAdd,
    mainTitle,
    type,
    refreshStats
}) => {
    const [searchedValue, setSearchedValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataLoader, setDataLoader] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState(``)
    const { allergies, medicines, diagnoses, vaccines, getTheUpdatedData } = useContext(ApplicationContext)
    const [list, setList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [selectedDescription, setSelectedDescription] = useState('')

    useEffect(() => {
        renderItems()
        localStorage.removeItem('search-text')
    }, [type]);

    // useEffect(() => {
    //     if(type === CATEGORIES_TYPES.diagnoses){
    //          const fetchDiagnosesData = async() => {
    //             setLoaderDetails(true, 'Wait a moment...')
    //             const result = await getAllProfileRecords();
    //             setList(result);
    //             setFilteredList(result);
    //             setLoaderDetails(false, '')
    //         }
    //         if(searchedValue.length >=3){
    //             fetchDiagnosesData()
    //         }
    //         else if(searchedValue.length == 1){
    //             setList([]);
    //             setFilteredList([]);
    //         }
    //     }
    //     alert('change')
    // },[searchedValue]);

    const renderItems = async () => {
        setLoaderDetails(true, 'Retreiving data from server...')
        if (type) {
            switch (type) {
                case CATEGORIES_TYPES.allergies:
                    (allergies && allergies.length > 0) ? setList(allergies) : setList(await getTheUpdatedData(CATEGORIES_TYPES.allergies));
                    break
                case CATEGORIES_TYPES.medicines:
                    (medicines && medicines.length > 0) ? setList(medicines) : setList(await getTheUpdatedData(CATEGORIES_TYPES.medicines));
                    break;
                case CATEGORIES_TYPES.diagnoses:
                    (diagnoses && diagnoses.length > 0) ? setList(diagnoses) : setList(await getTheUpdatedData(CATEGORIES_TYPES.diagnoses));
                    break;
                case CATEGORIES_TYPES.vaccines:
                    (vaccines && vaccines.length > 0) ? setList(vaccines) : setList(await getTheUpdatedData(CATEGORIES_TYPES.vaccines));
                    break
                default:
                    setList([])
            }
        }
        setLoaderDetails(false, ``)
    }

    const setLoaderDetails = (state, message) => {
        setDataLoader(state)
        setLoaderMessage(state ? message : ``)
    }

    const searchFromTheList = (searchText) => {

        if (type !== CATEGORIES_TYPES.diagnoses) {
            setLoaderDetails(true, 'Wait a moment...')
            setFilteredList(list.filter(({ description }) => description && description?.toString()?.toLowerCase().startsWith(searchText.toLowerCase())));
            setLoaderDetails(false, '')
        } else {
            const fetchDiagnosesData = async () => {
                setLoaderDetails(true, 'Wait a moment...')
                const result = await getAllProfileRecords(CATEGORIES_TYPES.diagnoses);
                setList(result);
                setFilteredList(result);
                setLoaderDetails(false, '')
            }
            if (searchText.length >= 2) {
                fetchDiagnosesData()
            }
            else if (searchText.length == 1) {
                setList([]);
                setFilteredList([]);
            }
        }
    }

    const saveDataToList = async () => {
        setLoading(true);
        const result = await addNewItem(type, selectedDescription);
        setLoading(false);
        if (result) {
            setShowAdd(false)
            refreshStats()
        }
    }

    return (
        <Modal
            show={showAdd}
            size='xl'
            onHide={() => setShowAdd(false)}
            animation={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {`Select ${mainTitle || ''}`}
                        {list.length > 0 && <span style={{ fontSize: '12px', color: '#666666' }}>{`${list.length} - ${mainTitle} Available`}</span>}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="addItemBodyContainer">
                    <input
                        className="searchFieldItem"
                        placeholder="Search here... (Please write at-least 3 character for better search)"
                        value={searchedValue}
                        disabled={dataLoader}
                        onChange={({ target: { value } }) => {
                            setSearchedValue(value);
                            localStorage.setItem('search-text', value);
                            if (value && value.length > 2) {
                                searchFromTheList(value)
                            } else {
                                setFilteredList([])
                            }
                        }} />
                    <div>

                    </div>
                    <div className="listContainer">
                        <>
                            {
                                dataLoader ? <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <CircularProgress size={30} color={'grey'} />
                                    <span style={{ fontSize: '12px', marginTop: '10px' }}>{loaderMessage}</span>
                                </div>
                                    : <>
                                        {filteredList && filteredList.length > 0 && filteredList.map(({ description }, index) => {
                                            return <span
                                                key={index}
                                                className="itemDataRow"
                                                onClick={() => {
                                                    setSearchedValue(description)
                                                    setFilteredList([])
                                                    setSelectedDescription(description)
                                                }}>{`${description}`}</span>
                                        })}
                                    </>
                            }
                        </>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="addItemButton"
                    disabled={selectedDescription == ''}
                    onClick={() => saveDataToList()}
                >
                    {loading ? (
                        <CircularProgress size={18} color={"inherit"} />
                    ) : (
                        <span>{`SUBMIT`}</span>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    )
}