import React from 'react';
import { Navigate } from 'react-router-dom';
import { routePaths } from './routesConfig';
import { getToken } from 'utilities';

const PublicRoute = ({ children }) => {
    const isLoggedIn = getToken() !== '';

    if (isLoggedIn) {
        return <Navigate to={routePaths.home} />;
    }

    return children;
};

export default PublicRoute;
