import React, { useState, useEffect, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import NavDropdown from 'react-bootstrap/NavDropdown';
import axios from "axios";
import { ApplicationContext } from 'shared'
import './styles.scss'
import { translateText } from "utilities";
import { languagesList } from "common";
import { Spinner } from "react-bootstrap";

export const TranslatePopup = ({
  showAdd,
  setShowAdd,
  mainTitle,
  type
}) => {
  const { categoryListing, setCategoryListing } = useContext(ApplicationContext)
  const [selectedTranslation, setSelectedTranslation] = useState('en')
  const [list, setList] = useState([])
  const [translatedList, setTranslatedList] = useState([])
  const [selecteddValue, setSelectedValue] = useState('');
  let dataList = []

  useEffect(() => {
    getTheCurrentList()
  }, [type]);

  const getTheCurrentList = async () => {
    setList(categoryListing.filter(item => item.type == type)[0]?.dataListing || []);
  }

  const translatedData = async () => {
    dataList = []
    list.map(async (item) => {
      translateText(item.description, selectedTranslation).then(response => {
        dataList.push({
          ...item,
          description: response
        })
        dataList.length == list.length && setTranslatedList(dataList)
      });

    })
  }

  useEffect(() => {
    if (selectedTranslation && list.length > 0) {
      if (selectedTranslation == 'en') {
        setTranslatedList(list)
      } else {
        translatedData();
      }
    }
  }, [selectedTranslation, list])

  return (
    <Modal
      show={showAdd}
      size="lg"
      onHide={() => setShowAdd(false)}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`My ${mainTitle || ''}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <select
            className="searchFieldItem"
            title="Select Language"
            value={selectedTranslation}
            onChange={({ target: { value } }) => {
              setSelectedTranslation(value)
            }}>
            {languagesList.length > 0 && languagesList.map(({ code, language }, index) => {
              return <option key={index} value={code}>{language}</option>
            })}
          </select>

          {list && list.length > 0 && <div className="listContainer">
            {translatedList.length == list.length ? list.map(({ description }, index) => {
              return <div key={index} style={{ display: 'flex', flexDirection: 'column', margin: 10 }}>
                <span style={{ fontSize: '15px', fontWeight: 'bold', marginTop: '5px', color: '#47B6F6' }}>{description}</span>
                <span>{translatedList[index].description}</span>
              </div>
            }) : <div style={{ justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', display: 'flex', flex: 1 }}>
              <Spinner animation="grow" />
            </div>}
          </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}