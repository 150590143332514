import React, { useState } from "react";
import "./styles.scss";
import { GenericButton, GenericModal, InputField } from "components";
import { Formik, Form } from "formik";
import { resetPasswordSchema } from "common/schemas";
import { images } from "common/images";
import { Link, useNavigate } from "react-router-dom";
import { performForgetPasswordThroughFirebase } from "services/firebaseServices";
import { ENUMS, constants } from "common";
import { routePaths } from "routes";
import { Navigation } from "components/navigation";

const ForgotPassword = () => {
  const { wmcLogo, mobileLogo, appStoreLogo, playStoreLogo } = images;
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.MODAL_TYPES.ERROR,
    title: "",
    body: "",
  });
  const handleKeyPress = (event, submitForm) => {
    if (event.which === 13) {
      submitForm();
    }
  };
  const initialValues = {
    email: "",
  };
  const navigation = useNavigate();
  const sendEmail = async (payload, loadingFalse) => {
    try {
      let result = await performForgetPasswordThroughFirebase(payload);
      loadingFalse();
      if (result) {
        setShowModal(true);
        setApiResponse({
          type: ENUMS.MODAL_TYPES.SUCCESS,
          title: "Success",
          body: "Please check your email a reset link has been sent",
        });
      }
    } catch (error) {
      setShowModal(true);
      setApiResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        title: "Error",
        body: error.error,
      });
    }
  };
  return (
    <div className="signin-container">
      <Navigation />
      <div className="leftPlan signin">
        <div className="leftPlanInner">
          <div style={{ marginLeft: 20, marginBottom: 20 }}>
            {/* <div className="wmcImagBox">
              <img src={wmcLogo} height="auto" width="100%" alt="no-image" style={{borderRadius: 0}}/>
            </div> */}
          </div>
          <div className="mobileImageBox">
            <img src={mobileLogo} height="auto" width="100%" />
            <div>
              <h1 className="msg">
                Get Our App Now Available on App Store and Play Store
              </h1>
              <div style={{ marginTop: 20, width: "60%" }}>
                <a href={constants.appStoreAppLink}>
                  <img src={appStoreLogo} height="auto" width="100%" />
                </a>
                <a href={constants.appStoreAppLink}>
                  <img src={playStoreLogo} height="auto" width="100%" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "50%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
        }}
        className="forget-passs"
      >
        <div className="form-container">
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            onSubmit={(values, formikActions) => {
              sendEmail(values, () => formikActions.setSubmitting(false));
            }}
            validationSchema={resetPasswordSchema}
            enableReinitialize
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              submitForm,
              isSubmitting,
            }) => (
              <Form onKeyPress={(e) => handleKeyPress(e, submitForm)}>
                <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                  <h1 className="welcome">Reset Password</h1>
                  <p className="signInMsg">Please confirm your email address</p>
                </div>
                <div className="block">
                  <InputField
                    icon={<span class="icon-email"></span>}
                    name={"email"}
                    id={"email"}
                    placeholder={"Email"}
                    error={touched.email && errors.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div className="block">
                  <GenericButton
                    loading={isSubmitting}
                    buttonText={"Reset password"}
                    onPress={handleSubmit}
                  />
                </div>
                <div className="forgetPasswordCont">
                  <Link to="/sign-in">
                    {" "}
                    <p className="noAccountLink">
                      Already have an account? Sign In
                    </p>
                  </Link>
                  <Link to="/sign-up">
                    {" "}
                    <p className="noAccountLink">No account? Sign Up</p>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
          <GenericModal
            show={showModal}
            type={apiResponse.type}
            title={apiResponse.title}
            body={apiResponse.body}
            buttonText={constants.ok}
            handleClose={() => {
              navigation({
                pathname: routePaths.signIn,
              });
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
