import React, { useState } from "react";
import { images } from "../../common/images";
import "./style.scss";

export function InputField({
  placeholder,
  icon,
  searchIcon,
  isPassword,
  error,
  fullWidth,
  handleChange,
  handleBlur,
  phone,
  validate,
  value,
  label,
  disabled,
  prefixerValue,
  customSign,
  customStyle,
  isSearch,
  onFocus,
  ...props
}) {
  const { eyeOpen, eyeClose } = images;
  const [isEyeOpen, setIsEyeOpen] = useState(false);

  return (
    <>
      {label && <div className="plan-labels">{label}</div>}
      <div
        className={
          fullWidth
            ? `input-field-container full-width-input-generic ${customStyle}`
            : `input-field-container ${customStyle} ${
                disabled ? "disable-input-field" : ""
              }`
        }
      >
        {phone && (
          <div className="additonal-one">
            <span> +</span>
          </div>
        )}
        {prefixerValue && (
          <div className="additonal-prefixes">
            <span>{prefixerValue}</span>
          </div>
        )}
        {customSign && (
          <div className="additonal-customSign">
            <span>{customSign}</span>
          </div>
        )}
        {icon}
        <input
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          className={
            disabled ? "isDisabled input-field-generic" : "input-field-generic"
          }
          type={
            isPassword && !isEyeOpen ? "password" : isSearch ? "search" : "text"
          }
          onFocus={onFocus}
          onChange={handleChange}
          onBlur={handleBlur}
          validate={validate}
          {...props}
        />
        {isPassword && (
          <img
            onClick={() => {
              console.log("click");
              setIsEyeOpen(!isEyeOpen);
            }}
            className={"eye-open"}
            src={isEyeOpen ? eyeOpen : eyeClose}
            alt="eye-icon"
          />
        )}
        {searchIcon}
      </div>
      <div className="error-input-container">
        {error ? <p className="form-error">{error}</p> : null}
      </div>
    </>
  );
}
