import React, { useState } from "react";
import "./styles.scss";
import { GenericButton, GenericModal, InputField } from "components";
import { Formik, Form } from "formik";
import { signinSchema } from "common/schemas";
import { images } from "common/images";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { routePaths } from "routes";
import { getUserDetails, performLoginThroughFirebase } from "services";
import { setProfile, setSecondaryProfile, setToken } from "utilities";
import { ENUMS, constants } from "common";
import { Navigation } from "components/navigation";

const SignIn = () => {
  const {
    getOurApp,
    titles: { welcome, pleaseSignIn, forgetPassword, noAccountSignUp },
  } = constants;
  const [showModal, setShowModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.MODAL_TYPES.ERROR,
    title: "",
    body: "",
  });
  const { wmcLogo, mobileLogo, appStoreLogo, playStoreLogo } = images;
  const handleKeyPress = (event, submitForm) => {
    if (event.which === 13) {
      submitForm();
    }
  };
  const initialValues = {
    email: "",
    password: "",
  };
  let navigate = useNavigate();
  const performLogin = async (payload, loadingFalse) => {
    try {
      const result = await performLoginThroughFirebase(payload);
      if (result) {
        const {
          user: { accessToken, uid, reloadUserInfo },
        } = result;
        setToken(accessToken);
        setProfile({
          uid,
          reloadUserInfo,
        });
        const responseData = await getUserDetails(uid);
        setSecondaryProfile(responseData);
        navigate({ pathname: routePaths.home });
      }
      loadingFalse();
    } catch (e) {
      loadingFalse();
      setShowModal(true);
      setApiResponse({
        type: ENUMS.MODAL_TYPES.ERROR,
        title: "Error",
        body: "Invalid Credentials",
      });
      console.log("Error : ", e.message);
    }
  };

  return (
    <div className="signin-container">
      <Navigation />
      <div className="leftPlan signin">
        <div className="leftPlanInner">
          <div className="wmc-box-wrap">
            {/* <div className="wmcImagBox">
              <img src={wmcLogo} height="auto" width="100%" alt="no-image" style={{borderRadius: 0}} />
            </div> */}
          </div>
          <div className="mobileImageBox">
            <img src={mobileLogo} height="auto" width="100%" />
            <div>
              <h1 className="msg">{getOurApp}</h1>
              <div className="app-store-wrap-link">
                <a href={constants.appStoreAppLink}>
                  <img src={appStoreLogo} height="auto" width="100%" />
                </a>
                <a href={constants.playStoreAppLink}>
                  <img src={playStoreLogo} height="auto" width="100%" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-wrap signin-form">
        <div className="form-container">
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            onSubmit={(values, formikActions) => {
              performLogin(values, () => formikActions.setSubmitting(false));
            }}
            validationSchema={signinSchema}
            enableReinitialize
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              submitForm,
              values,
              isSubmitting,
            }) => (
              <Form onKeyPress={(e) => handleKeyPress(e, submitForm)}>
                <div className="heading-wrap">
                  <h1 className="welcome">{welcome} </h1>
                  <p className="signInMsg">{pleaseSignIn}</p>
                </div>
                <div className="block">
                  <InputField
                    icon={<span class="icon-email"></span>}
                    name={"email"}
                    id={"email"}
                    placeholder={"Email"}
                    value={values.email}
                    error={touched.email && errors.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>
                <div className="block">
                  <InputField
                    customStyle={"pass-input-custom-style"}
                    id={"password"}
                    name={"password"}
                    placeholder={"Password"}
                    error={touched.password && errors.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    value={values.password}
                    isPassword
                    isSearch
                  />
                </div>
                <div className="block">
                  <GenericButton
                    buttonText={"Sign In"}
                    onPress={handleSubmit}
                    loading={isSubmitting}
                  />
                </div>
                <div className="forgetPasswordCont">
                  <Link to="/forgot-password">
                    {" "}
                    <p className="noAccountLink">{forgetPassword}</p>
                  </Link>
                  <Link to="/sign-up">
                    {" "}
                    <p className="noAccountLink">{noAccountSignUp}</p>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>

          <GenericModal
            show={showModal}
            type={apiResponse.type}
            title={apiResponse.title}
            body={apiResponse.body}
            buttonText={constants.ok}
            handleClose={() => {
              setShowModal(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
