import {
  Home,
  SignIn,
  SignUp,
  Locations,
  ManageAccount,
  ForgotPassword,
  Dashboard,
  PriceTable,
} from "pages";

export const pageNames = {
  home: `Home`,
  signUp: `SignUp`,
  companySignUp: `SignUp`,
  signIn: `SignIn`,
  forgotPassword: `ForgotPassword`,
  locations: `Locations`,
  manageAccount: `ManageAccount`,
  priceTable: "PriceTable",
};

export const routePaths = {
  home: `/`,
  signUp: `/sign-up`,
  companySignup: `/:company/sign-up`,
  signIn: `/sign-in`,
  forgotPassword: `/forgot-password`,
  locations: `/locations`,
  manageAccount: `/manage-account`,
  priceTable: `/price-table`,
};

export const PAGE_ENUMS = {
  HOME: 1,
  MANAGE_ACCOUNT: 2,
  LOCATIONS: 3,
};

export const privateRoutes = {
  [routePaths.home]: {
    name: pageNames.home,
    path: routePaths.home,
    component: Dashboard,
    subComponent: Home,
    selectedId: PAGE_ENUMS.HOME,
  },
  [routePaths.locations]: {
    name: pageNames.locations,
    path: routePaths.locations,
    component: Dashboard,
    subComponent: Locations,
    selectedId: PAGE_ENUMS.LOCATIONS,
  },
  [routePaths.manageAccount]: {
    name: pageNames.manageAccount,
    path: routePaths.manageAccount,
    component: Dashboard,
    subComponent: ManageAccount,
    selectedId: PAGE_ENUMS.MANAGE_ACCOUNT,
  },
};

export const publicRoutes = {
  [routePaths.signIn]: {
    name: pageNames.signIn,
    path: routePaths.signIn,
    component: SignIn,
  },
  [routePaths.signUp]: {
    name: pageNames.signUp,
    path: routePaths.signUp,
    component: SignUp,
  },
  [routePaths.companySignup]: {
    name: pageNames.companySignUp,
    path: routePaths.companySignup,
    component: SignUp,
  },
  [routePaths.forgotPassword]: {
    name: pageNames.forgotPassword,
    path: routePaths.forgotPassword,
    component: ForgotPassword,
  },
  [routePaths.priceTable]: {
    name: pageNames.priceTable,
    path: routePaths.priceTable,
    component: PriceTable,
  },
};
