export const colors = {
  disabledRed: "#ed1d24",
  activeColor: "#ed1d24",
  primary: "#dd3333",
  lightBlue: "#4285F4",
  completedColor: "#a10308",
  dropDownBackground: " #F3F7FD",
  green: "#22b141",
  gray: "#7C7C7C",
  black2: "#2D2D2D",
  darkRed: "#dd3333",
};

export const fonts = {
  tommyFont: "MADE Tommy",
};
