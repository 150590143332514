import React, { useState, useEffect, useContext, createRef } from "react";
import "./styles.scss";
import { images } from "common";
import { CiMapPin } from "react-icons/ci";
import { ApplicationContext } from "shared";
import { GoDotFill } from "react-icons/go";


// const libraries = ["places"];
const mapContainerStyle = {
    height: "100vh",
};

const Locations = () => {

    const { currentLocation, setCurrentLocation } = useContext(ApplicationContext)

    useEffect(() => {
        if (document.getElementById("map")) {
            initMap('hospital')
            initMap('pharmacy')
        }
    }, [currentLocation])

    var map;
    var bounds;
    var infowindow;

    function initMap(queryLocation) {
        var pyrmont = new window.google.maps.LatLng(currentLocation.lat, currentLocation.lng);
        map = new window.google.maps.Map(document.getElementById("map"), {
            center: pyrmont,
            zoom: 18
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    // Create a blue dot marker at the user's current location
                    const marker = new window.google.maps.Marker({
                        position: userLocation,
                        map: map,
                        icon: {
                            url: images.bluePin,
                            scaledSize: new window.google.maps.Size(20, 20)
                        },
                        title: "Your Location",
                    });

                    // Center the map on the user's location
                    map.setCenter(userLocation);
                },
                (error) => {
                    console.error("Error getting current location:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }

        bounds = new window.google.maps.LatLngBounds();
        infowindow = new window.google.maps.InfoWindow();

        const service = new window.google.maps.places.PlacesService(map);

        var request = {
            location: pyrmont,
            radius: '1000',
            query: queryLocation
        };
        service.textSearch(request, callback);
    }

    function callback(results, status) {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            for (var i = 0; i < results.length; i++) {
                createMarker(results[i]);
            }
        }
        // ===== We might need that in future ===== //
        // createMarker({
        //     geometry: {
        //         location: {
        //             lat: currentLocation.lat,
        //             lng: currentLocation.lng
        //         }
        //     },
        //     name: 'My Location',
        //     types: ['']
        // })
      //  map.fitBounds(bounds);
    }

    function createMarker(place) {
        var marker = new window.google.maps.Marker({
            map: map,
            position: place.geometry.location,
            icon: {
                url: place.types.includes('hospital') ? images.hospitalIcon : (place.types.includes('pharmacy') ? images.pharmacyIcon : images.bluePin),
                scaledSize: new window.google.maps.Size(75, 75)
            },
        });
        window.google.maps.event.addListener(marker, 'click', function () {
            // display the place "name" and "place_id" in the infowindow.
            infowindow.setContent(place.name || '');
            infowindow.open(map, this);
        });

        bounds.extend(marker.getPosition());

        return marker;
    }

    return (
        <div style={{ width: "100%", height: '100%' }}>
            <div id='map' style={mapContainerStyle}>
                <img src={images.wmcLogo} style={{zIndex:1000, height: 100, width: 150}} />
            </div>
        </div>

    )
}

export default Locations;