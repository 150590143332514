import React from 'react';
import { Navigate } from 'react-router-dom';
import { routePaths } from './routesConfig';
import { getToken } from 'utilities';

const PrivateRoute = ({ children }) => {
    const isLoggedIn = getToken() !== '';

    if (!isLoggedIn) {
        return <Navigate to={routePaths.signIn} />;
    }

    return children;
};

export default PrivateRoute;
