export function isEighteenOrOlder(dob) {
    // Parse the date of birth
    const dobDate = new Date(dob);
    
    // Get the current date
    const currentDate = new Date();
    
    // Calculate the difference in years
    let age = currentDate.getFullYear() - dobDate.getFullYear();
    
    // Adjust the age if the current date is before the birthday in the current year
    const monthDiff = currentDate.getMonth() - dobDate.getMonth();
    const dayDiff = currentDate.getDate() - dobDate.getDate();
    
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }
    
    // Check if the age is 18 or older
    return age >= 18;
}

// Example usage:
// const dob = 'Sat May 28 2011 00:00:00 GMT+0530 (India Standard Time)';

