import * as Yup from "yup";
import { constants } from "./constants";
import { isEighteenOrOlder } from "./isEighteenOrOlder";

const {
  ERROR_PLACEHOLDERS: {
    INVALID_EMAIL,
    EMAIL,
    PASSWORD_MUST_BE,
    PASSWORD,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    ADDRESS,
    DOB,
    ISDOBEIGHTEENYEAROLD,
    EIGHTEENOROLDER,
    CONFIRM_PASSWORD,
    NEW_PASSWORD_MATCH_ERROR,
    CITY,
    STATE,
    ZIP,
    HOME_PHONE,
    REGION,
    POSTAL_CODE,
    COUNTRY,
    PLEASE_SELECT,
    INSURANCECOMPANYNAME,
    POLICY_NUMBER,
    EMERGENCY_CONTACT
  },
} = constants;

export const signinSchema = Yup.object({
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  password: Yup.string().min(3, PASSWORD_MUST_BE).required(PASSWORD),
});

export const resetPasswordSchema = Yup.object({
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
});
export const signUpStep1Schema = Yup.object({
  firstName: Yup.string().required(FIRST_NAME),
  lastName: Yup.string().required(LAST_NAME),
  dob: Yup.string().required(DOB).test(ISDOBEIGHTEENYEAROLD, EIGHTEENOROLDER, (value)=> { 
      return isEighteenOrOlder(value);
  }),
  phone: Yup.string().min(10).required(PHONE),
  gender: Yup.string().required("Gender is required"),
  nationality: Yup.string().required("Nationality is required"),
  socialNum: Yup.string()
  // .required("National ID Number is required"),
});
export const signUpStep2Schema = Yup.object({
  address: Yup.string().required(ADDRESS),
  city: Yup.string().required(CITY),
  state: Yup.string().required(REGION),
  country: Yup.string().required(COUNTRY),
  zipCode: Yup.string().required(POSTAL_CODE),
});
export const signUpStep3Schema = Yup.object({
  insuranceType: Yup.string(),
  insuranceCompany: Yup.string(),
  policyNumber: Yup.string(),
  emergencyNumber: Yup.string(),
});

export const signUpStep4Schema = Yup.object({
  emergencyName: Yup.string().required("Emergency Name is required"),
  emergencyPhone: Yup.string().required("Emergency Phone is required"),
  emergencyRelation: Yup.string().required(
    "Emergency Relationship is required"
  ),
});

export const signUpSchema = Yup.object({
  email: Yup.string().trim().email(INVALID_EMAIL).required(EMAIL),
  password: Yup.string().min(3, PASSWORD_MUST_BE).required(PASSWORD),
  confirmPassword: Yup.string()
    .required(CONFIRM_PASSWORD)
    .oneOf([Yup.ref("password"), null], NEW_PASSWORD_MATCH_ERROR),
});

export const paymentSchema = Yup.object({
  cardHolderName: Yup.string().required("Card Holder Name is required"),
});
