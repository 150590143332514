import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.scss";

export function PhoneInputField({
  placeholder,
  handleChange,
  handleBlur,
  value,
  error,
  ...props
}) {
  return (
    <>
      <PhoneInput
        name={"homephone"}
        id={"homephone"}
        inputStyle={{ boxShadow: 'none' }}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        {...props}
      />
      <div className="error-input-container">
        {error ? <p className="form-error">{error}</p> : null}
      </div>
    </>
  );
}
