import { constants } from "common";
import { GenericButton } from "components/generic-button";
import React from "react";
import { useParams } from "react-router-dom";

export const ActionButtons = (props) => {
  const { back, next, submit } = constants;
  const handleBack = () => {
    props.previousStep();
    const object1 = props.userDetails;
    const object2 = props.values;
    const mergedObject = Object.assign({}, object1, object2);
    props.setUserDetails(mergedObject);
  };
  const { company } = useParams();
  const isCompanyLogin = !!company;
  const handleNext = () => {
    props.nextStep();
  };
  return (
    <div className="signup-btn-container">
      {props.currentStep > 1 && (
        <GenericButton
          customStyle={"custom-btn-style"}
          buttonText={back}
          onPress={handleBack}
          id="Action-button-step1"
        />
      )}
      {props.currentStep < props.totalSteps && (
        <GenericButton
          customStyle={"custom-btn-style"}
          id="Action-button-step2"
          buttonText={next}
          onPress={() => {
            props.handleSubmit(() => handleNext());
            if (props.activeStep == 3 || props.activeStep == 4) {
            }
          }}
        />
      )}
      {isCompanyLogin && props.currentStep == props.totalSteps && (
        <GenericButton
          customStyle={"custom-btn-style"}
          id="Action-button-step2"
          buttonText={submit}
          loading={props.loading || false}
          onPress={() => {
            props.handleSubmit();
          }}
        />
      )}
    </div>
  );
};
