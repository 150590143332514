export const firebaseConfig = {
  apiKey: "AIzaSyAsw_B2AFHDXEN1HKRLv31IGNccui6frY0",
  authDomain: "world-medical-card-prod.firebaseapp.com",
  databaseURL: "https://world-medical-card-prod-default-rtdb.firebaseio.com",
  projectId: "world-medical-card-prod",
  storageBucket: "world-medical-card-prod.appspot.com",
  messagingSenderId: "693992819935",
  appId: "1:693992819935:web:1124e6c059bb5d7d742d6a",
  measurementId: "G-W1XF11SNBM",
};
export const error = { errorBoundry: "Something Went Wrong!" };
export const constants = {
  titles: {
    welcome: "Welcome",
    pleaseSignIn: "Please sign in to continue",
    forgetPassword: "Forgot password?",
    noAccountSignUp: "No account? Sign Up",
  },
  gatewayKeys: {
    test: `sk_test_DZuHUsmLR9bNuH9T37w8wy5x`,
    live: `sk_live_Qp8SYrVKkOVWyiPA23JeHJQV`
  //  live: `pk_live_kPZWO9boj1MylWK05J2kRm01`
  },
  // siteBaseURL: `https://wmcwebsolution.azurewebsites.net`,
  siteBaseURL: `http://app.worldmedicalcard.io`,
  googleAPIKey: `AIzaSyDozz561XgHt-htCwSSa1pchzTvqEnQs3U`,
  playStoreAppLink:
    "https://play.google.com/store/search?q=world%20medical%20card&c=apps&hl=en&gl=US",
  appStoreAppLink:
    "https://apps.apple.com/us/app/world-medical-card/id1000435682",
  ok: "OK",
  confirm: "Confirm",
  cancel: "Cancel",
  ERROR_PLACEHOLDERS: {
    EMAIL: "Email is required",
    PASSWORD_REQUIRED: "Password is required",
    PASSWORD: "Password is required",
    CONFIRM_PASSWORD: "Confirm password is required",
    PASSWORD_MUST_BE: "Password must be at least 6 characters ",
    NEW_PASSWORD: "New password is required",
    NEW_PASSWORD_MATCH_ERROR: `New and confirm passwords must match`,
    INVALID_EMAIL: "Email address is invalid",
    INVALID_WEBSITE: "Website is invalid",
    PASSWORD_MATCH: "Password must be matched",
    NAME: "Name is required",
    WEBSITE: "Website is required",
    COMPANY_NAME: "Company name is required",
    TAX_ID: "Tax id is required",
    DESCRIPTION: "Description is required",
    COUPON_CODE: "Coupon code is required",
    COUPON_TYPE: "Coupon type is required",
    FIXED_AMOUNT: "Amount is required",
    MINIMUM_DISCOUNT_AMOUNT: "Minimum discount amount is required",
    DISCOUNT_AMOUNT: "Discount amount is required",
    REDEMPTIONS: "Redemption count is required",
    COUPON_START_DATE: "Coupon start date is required",
    COUPON_EXPIRY_DATE: "Coupon expiry date is required",
    SERVICE_TYPE: "Service type is required",
    FIRST_NAME: "First name is required",
    LAST_NAME: "Last name is required",
    DOB: "Date of birth is required",
    ISDOBEIGHTEENYEAROLD: 'is-18-or-older',
    EIGHTEENOROLDER: "You must be 18 years or older",
    PHONE: "Phone number is required",
    HOME_PHONE: "Home Phone number is required",
    ADDRESS: "Address is required",
    CITY: "City is required",
    STATE: "State is required",
    REGION: "Region is required",
    COUNTRY: "Country is required",
    POSTAL_CODE: "Postal Code is required",
    ZIP: "Zip code is required",
    DATE: "Date is required",
    STATUS: "Status is required",
    PLEASE_SELECT: "Please select",
    PLEASE_ENTER: "Please enter",
    INSURANCECOMPANYNAME: "Please provide insurance company name",
    POLICY_NUMBER: "Policy number is required",
    EMERGENCY_CONTACT : "Emergency Contact is required"
  },
  errorMessages: {
    CardName: "Please enter card holder name",
    cardNumber: "Please enter card number",
    cardExpiry: "Please enter expiry date",
    cardCvv: "Please enter CVC",
  },
  formHeadings: {
    step1: "Sign Up",
    step2: "Personal Info",
    step3: "Address",
    step4: "Insurance",
    step5: "Emergency",
    step6: "Checkout",
  },
  back: "← Back",
  next: "Next →",
  submit: "Create Account",
  generateText: "Generate Password",
  getOurApp: `Get Our App
      Now Available
      on App Store
      and Play Store`,
  signUp: "Sign Up",
  createAndAccount: "Create an account",
  pleaseFilloutPersonalInfo:
    "Please fill your personal information to create an account.",
  registerNewWMCAcc: "Register a new account",
  alreadyHaveAndAcc: "Already have an account? Sign In",
  colors: {
    allergiesColor: `#F7D1F4`,
    medicineColor: `#D9E2FC`,
    diagnoseColor: `#FCF0CE`,
    vaccineColor: `#D1FDBD`,
    documentColor: `#BBB6FA`,
  },
  profileSectionTitle: {
    personalInformation: `Personal Information`,
    postalAddress: `Address`,
    insurance: `Insurance`,
    emergency: `Emergency`,
    deleteUser: `Delete User`
  },
  genericModal: {
    success: "Success",
    error: "Error",
    alert: "Alert",
    sure: "Are You Sure?",
  },
  icon: "Icon",
  placeholders: {
    firstName: "First Name*",
    lastName: "Last Name*",
    email: "Email*",
    dob: "Date of Birth",
    phone: "Phone Number*",
    city: "City*",
    enterCity: "Enter City",
    state: "State*",
    region: "State*",
    country: "Country*",
    enterState: "Enter State",
    address: "Address*",
    zipCode: "Zip Code*",
    postalCode: "Postal Code*",
    enterZipCode: "Enter Zip Code",
    password: "Password",
    newPassword: "New Password",
    enterNewPassword: "Enter New Password",
    confirmPassword: "Confirm Password",
    confirmNewPassword: "Confirm New Password",
    enterHolderName: "Enter Card Holder Name",
    enterPolicyId: "Enter Plan Id",
    typeReason: "Type Reason",
    claimNumber: "Claim Id",
    policyID: "Plan Id",
    policyIDNumber: "Plan Id",
    invoiceId: "Invoice Id",
    invoiceIdNumber: "Enter Invoice Id",
    subscriptionId: "Subscription Id",
    refundId: "Refund Id",
    refundIdNumber: "Enter Refund Id",
    subscriptionType: "Subscription Type",
    currentPassword: `Current Password`,
    enterCurrentPassword: `Enter Current Password`,
    emailAddress: "Your email address",
    cardHolderName: "Card Holder Name*",
  },
  fieldTypeEnums: {
    streetNumber: `street_number`,
    route: `route`,
    naibhour: `neighborhood`,
    locality: `locality`,
    admin: `administrative_area_level_1`,
    code: `postal_code`,
    country: "country",
  },
};
// export const selectedStripeKey = `sk_test_DZuHUsmLR9bNuH9T37w8wy5x`;
export const selectedStripeKey = `sk_live_Qp8SYrVKkOVWyiPA23JeHJQV`;

export const dateFormat = `DD-MM-YYYY`;

export const documentTypes = [
  {
    value: `Certificate`,
    label: `Certificate`,
  },
  {
    value: `Discharge Summary`,
    label: `Discharge Summary`,
  },
  {
    value: `Insurance`,
    label: `Insurance`,
  },
  {
    value: `Living Will`,
    label: `Living Will`,
  },
  {
    value: `Passport`,
    label: `Passport`,
  },
  {
    value: `Prescription`,
    label: `Prescription`,
  },
  {
    value: `Travel Document`,
    label: `Travel Document`,
  },
  {
    value: `X-Ray`,
    label: `X-Ray`,
  },
  {
    value: `Other`,
    label: `Other`,
  },
];

export const CATEGORIES_TYPES = {
  allergies: `allergies`,
  medicines: `medicine`,
  diagnoses: `diagnoses`,
  vaccines: `vaccines`,
  documents: `documents`,
};

export const ENUMS = {
  MODAL_TYPES: {
    SUCCESS: 1,
    ERROR: 2,
    ALERT: 3,
    SURE: 4,
    INFO: 5,
  },
};

export const GenderList = [
  {
    value: `Male`,
    label: `Male`,
  },
  {
    value: `Female`,
    label: `Female`,
  },
];

export const OrganDonarOptions = [
  {
    value: true,
    label: `Yes`,
  },
  {
    value: false,
    label: `No`,
  },
]

export const OrganDonarList = [
  {
    value: `Yes`,
    label: `Yes`,
  },
  {
    value: `No`,
    label: `No`,
  },
];

export const InsuranceTypesList = [
  {
    value: `Health`,
    label: `Health`,
  },
  {
    value: `Travel`,
    label: `Travel`,
  },
];
export const emergencyRelationshipList = [
  {
    value: `Father`,
    label: `Father`,
  },
  {
    value: `Mother`,
    label: `Mother`,
  },
  {
    value: `Brother`,
    label: `Brother`,
  },
  {
    value: `Sister`,
    label: `Sister`,
  },
  {
    value: `Son`,
    label: `Son`,
  },
  {
    value: `Daughter`,
    label: `Daughter`,
  },
  {
    value: `Husband`,
    label: `Husband`,
  },
  {
    value: `Wife`,
    label: `Wife`,
  },
  {
    value: `Grand Father`,
    label: `Grand Father`,
  },
  {
    value: `Grand Mother`,
    label: `Grand Mother`,
  },
  {
    value: `Boyfriend`,
    label: `Boyfriend`,
  },
  {
    value: `Girlfriend`,
    label: `Girlfriend`,
  },
  {
    value: `Other`,
    label: `Other`,
  },
]
