import React from "react";
import "./styles.scss";
import { RibbonContainer, Right, Ribbon } from "react-ribbons";
import { GenericButton } from "components";

const PriceTable = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "60%",
          width: "22%",
          borderRadius: 20,
        }}
      >
        <RibbonContainer>
          <Ribbon
            side="left"
            type="corner"
            size="large"
            backgroundColor="#cc0000"
            color="#ccffff"
            fontFamily="sans"
            withStripes
          >
            OFFER
          </Ribbon>
          <div className="price-table-content">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>Private</h3>
              <div
                style={{
                  display: "flex",
                }}
              >
                <span style={{ marginRight: 3 }}>€</span>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <h3>9,95</h3>
                  <p style={{ color: "#828282", marginLeft: 2 }}>/year</p>
                </div>
              </div>
            </div>
            <ul class="sample">
              <li>Instantly locate Hospitals & Pharmacies</li>
              <li>
                <span>
                  Translate Allergies, Medicine, Diagnoses & Vaccines into 135
                  languages
                </span>
              </li>
              <li>Store health documents & prescriptions</li>
              <li>Add Insurance Information</li>
              <li>Share your health information</li>
              <li>WMC Emergency Card Included</li>
            </ul>
            <GenericButton
              buttonText={"Order Now"}
              customStyle={"order-now-button"}
            />
          </div>
        </RibbonContainer>
      </div>
      <div
        style={{
          height: "60%",
          width: "22%",
          borderRadius: 20,
          marginLeft: 30,
        }}
      >
        <div className="corporate-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h3>Corporate</h3>
            <div
              style={{
                display: "flex",
              }}
            >
              <span style={{ marginRight: 3 }}>€</span>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <h3>11,95</h3>
                <p style={{ color: "#828282", marginLeft: 2 }}>/year</p>
              </div>
            </div>
            <span>
              Contact us to receive a customised offer including customer
              support and co-branding.
            </span>
          </div>
          <GenericButton
            buttonText={"Contact"}
            customStyle={"contact-now-button"}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceTable;
