import React from "react";
import { Route } from "react-router-dom";
import { routePaths } from "routes";
import { error } from "common/constants";

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };
  static getDerivedStateFromError(error) {
    return { error: true };
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <p>{error.errorBoundry}</p>
          <Route to={routePaths.login} />
        </>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
