import React, { useState, useEffect } from "react";
import ApplicationContext from "./context";
import { CATEGORIES_TYPES, images, constants } from "common";
import { getAllProfileRecords, getUserDetails } from "services/firebaseServices";
import { getProfile, getToken, setSecondaryProfile } from "utilities";

const ContextProvider = ({ children }) => {
  const { colors } = constants;
  const [allergies, setAllergies] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [diagnoses, setDiagnoses] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [fieldCount, setFieldCount] = useState(0);
  const [fields, setFields] = useState({});
  const [userData, setUserData] = useState({});
  const [emergencyfields, setEmergencyFields] = useState({});
  const [emergencyFieldCount, setEmergencyFieldCount] = useState(0);
  const [currentLocation, setCurrentLocation] = useState({lat: 31.5038131, lng: 74.3481095});
  const [showValidationMsg, setShowValidationMsg] = useState(false);

  const [categoryListing, setCategoryListing] = useState([
    {
      mainTitle: `Diagnoses`,
      type: CATEGORIES_TYPES.diagnoses,
      dataListing: [],
      background: colors.allergiesColor,
      icon: images.allergyIcon,
    },
    {
      mainTitle: `Medicines`,
      type: CATEGORIES_TYPES.medicines,
      dataListing: [],
      background: colors.medicineColor,
      icon: images.medicineIcon,
    },
    {
      mainTitle: `Allergies`,
      type: CATEGORIES_TYPES.allergies,
      dataListing: [],
      background: colors.diagnoseColor,
      icon: images.diagnoseIcon,
    },
    {
      mainTitle: `Vaccines`,
      type: CATEGORIES_TYPES.vaccines,
      dataListing: [],
      background: colors.vaccineColor,
      icon: images.vaccineIcon,
    },
    {
      mainTitle: `Documents`,
      type: CATEGORIES_TYPES.documents,
      dataListing: [],
      background: colors.documentColor,
      icon: images.documentIcon,
    },
  ]);

  useEffect(() => {
    getLocation();
    getTheUpdatedUserDetals()
  }, []);

  const getTheUpdatedUserDetals = async () => {
    const isAuthenticated = getToken();
    const userProfile = getProfile();
    if(isAuthenticated && userProfile && userProfile.uid){
      const updateData = await getUserDetails(userProfile.uid);
      setUserData(updateData)
      setSecondaryProfile(updateData);
    }
  }

  const getTheUpdatedData = async (selectedType) => {
    const isAuthenticated = getToken();
    if (isAuthenticated && selectedType) {
      const records = await getAllProfileRecords(selectedType);

      switch (selectedType) {
        case CATEGORIES_TYPES.allergies:
          setAllergies(allergies.length == 0 ? records : []);
          break;

        case CATEGORIES_TYPES.medicines:
          setMedicines(medicines.length == 0 ? records : []);
          break;

        case CATEGORIES_TYPES.diagnoses:
          setDiagnoses(diagnoses.length == 0 ? records : []);
          break;

        case CATEGORIES_TYPES.vaccines:
          setVaccines(vaccines.length == 0 ? records : []);
          break;
      }

      return records || [];
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {}
      );
    }
  };

  return (
    <ApplicationContext.Provider
      value={{
        setShowValidationMsg,
        showValidationMsg,
        allergies,
        medicines,
        diagnoses,
        vaccines,
        categoryListing,
        currentLocation,
        emergencyfields,
        setEmergencyFields,
        emergencyFieldCount,
        setEmergencyFieldCount,
        fields,
        setFields,
        fieldCount,
        setFieldCount,
        setAllergies,
        setMedicines,
        setDiagnoses,
        setVaccines,
        setCategoryListing,
        setCurrentLocation,
        getTheUpdatedData,
        userData,
        setUserData
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ContextProvider;
