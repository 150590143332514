export const images = {
  eyeOpen: "/images/eye-open.svg",
  eyeClose: "/images/eye-close.svg",
  wmcLogo: "/images/WMC_logo_slogan_black.png",
  mobileLogo: "/images/mobile-logo.png",
  playStoreLogo: "/images/google-play-store-badge.png",
  appStoreLogo: "/images/app-store-logo.png",
  allergyIcon: `/images/allergy-icon.svg`,
  medicineIcon: `/images/medicine-icon.svg`,
  diagnoseIcon: `/images/diagnose-icon.svg`,
  vaccineIcon: `/images/vaccine-icon.svg`,
  documentIcon: `/images/document-icon.svg`,
  delete: "/images/deleteSymbol.svg",
  error: "/images/errorModal.svg",
  alert: "/images/alertModal.svg",
  success: "/images/successModal.svg",
  deleteIcon: "/images/delete-icon.svg",
  pharmacyIcon: "/images/pharmacy-icon.png",
  hospitalIcon: "/images/hospital-icon.png",
  cardUnlock: "/images/card-unlock.svg",
  cardWithoutLock: "/images/card-withoutlock.svg",
  cardWithLock: "/images/card-with-lock.svg",
  sure: "/images/sure.svg",
  bluePin: `/images/blue-pin.png`
};
