import React, { useState } from "react";
import "./styles.css";
import {
  MdOutlineExpandMore,
  MdOutlineExpandLess,
} from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { IoGlobeOutline } from "react-icons/io5";
import { CATEGORIES_TYPES, ENUMS, constants } from "common";
import { CiShare2 } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { CircularProgress } from "@mui/material";

export const HomeCategorySection = ({
  mainTitle,
  type,
  icon,
  background,
  dataListing,
  onAddButtonClicked,
  onTranslateButtonClicked,
  onShareButtonClicked,
  setItemDescription,
  isLoading,
  setShowConfirmationModal,
  setApiResponse,
  selectedType,
  setSelectedType
}) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <div className="itemContainer">
      <div
        className={isExpanded ? "itemHeader" : "itemHeaderClosed"}
        onClick={() => setExpanded(!isExpanded)}
      >
        <div className="iconContainer">
          <div
            className="iconInnerContainer"
            style={{ backgroundColor: background }}
          >
            <img src={icon} />
          </div>
        </div>
        <div className="titleContainer">
          <span className="mainTitleStyling">{mainTitle}</span>
          <span className="countValueStyling">
            {dataListing && dataListing.length
              ? `${dataListing.length} Item(s)`
              : `No ${mainTitle} Added`}
          </span>
        </div>
        <div className="iconContainer">
          {isExpanded ? (
            <MdOutlineExpandLess
              size={40}
              color="grey"
              onClick={() => {
                setExpanded(false)
                setSelectedType(type)
              }}
            />
          ) : (
            <MdOutlineExpandMore
              size={40}
              color="grey"
              onClick={() => {
                setExpanded(true)
                setSelectedType(type)
              }}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div style={{ padding: "20px" }}>
          <div style={{ flexDirection: "column", display: "flex" }}>
            {type !== CATEGORIES_TYPES.documents ? (
              <>
                {(isLoading && (selectedType == type)) ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={25} color={"inherit"} />
                  </div>
                ) : (
                  dataListing &&
                  dataListing.length > 0 &&
                  dataListing.map(({ description }, index) => (
                    <div className="addedItemContainer" key={index}>
                      <span className="addItemTitle">{`${description}`}</span>
                      <RxCross2
                        size={25}
                        color="grey"
                        style={{ cursor: "pointer" }}
                        onClick={async () => {
                          setSelectedType(type);
                          setItemDescription({
                            type: type,
                            description: description,
                          });
                          setShowConfirmationModal(true);
                          setApiResponse({
                            type: ENUMS.MODAL_TYPES.SURE,
                            title: constants.genericModal.sure,
                            description: "",
                          });
                        }}
                      />
                    </div>
                  ))
                )}
              </>
            ) : (
              <>
                {dataListing &&
                  dataListing.length > 0 &&
                  dataListing.map((item, index) => {
                    const { category, title, description, url } = item;
                    return (
                      <a
                        className="addedDocumentContainer"
                        href={url}
                        target="_blank"
                        key={index}
                      >
                        <div className="addedDocumentTextContainer">
                          <span
                            style={{ fontSize: "10px", fontWeight: "bolder" }}
                          >
                            {category}
                          </span>
                          <span style={{ color: "black" }}>{title}</span>
                          <span style={{ color: "grey" }}>{description}</span>
                        </div>
                      </a>
                    );
                  })}
              </>
            )}
          </div>
          <div className="buttonContainer">
            <span
              className="buttonStyling"
              type="button"
              onClick={onAddButtonClicked}
            >
              <span className="btn-icon-cont">
                <span className="plus-btn-icon">
                  <IoMdAdd size={20} />
                </span>
                ADD
              </span>
            </span>

            {!isLoading && (
              <>
                {type !== CATEGORIES_TYPES.documents &&
                  dataListing &&
                  dataListing.length > 0 && (
                    <div
                      className="buttonStyling"
                      type="button"
                      onClick={onTranslateButtonClicked}
                    >
                      <span className="btn-icon-cont">
                        <span className="btn-icon">
                          <IoGlobeOutline size={20} />
                        </span>
                        <span>TRANSLATE</span>
                      </span>
                    </div>
                  )}
                {type !== CATEGORIES_TYPES.documents &&
                  dataListing &&
                  dataListing.length > 0 && (
                    <div
                      className="buttonStyling"
                      type="button"
                      onClick={onShareButtonClicked}
                    >
                      <span className="btn-icon-cont">
                        <span className="btn-icon">
                          <CiShare2 size={20} />
                        </span>
                        SHARE
                      </span>
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
