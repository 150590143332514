import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCA--REJmLJJQgIWAfReiaORqsEDBAa0Gs",
  authDomain: "world-medical-card-prod.firebaseapp.com",
  databaseURL: "https://world-medical-card-prod-default-rtdb.firebaseio.com",
  projectId: "world-medical-card-prod",
  storageBucket: "world-medical-card-prod.appspot.com",
  messagingSenderId: "693992819935",
  appId: "1:693992819935:web:02161dc68bb51bd6742d6a",
  measurementId: "G-2HX5K46CS3",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const fireStoreDB = getFirestore(app);
