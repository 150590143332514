import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./styles.scss";
import { ApplicationContext } from "shared";
import { CATEGORIES_TYPES, documentTypes } from "common";
import { addNewDocument, addNewItem } from "services";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getProfile, getSecondaryProfile } from "utilities";

const storage = getStorage();

export const AddDocumentPopup = ({
  showAdd,
  setShowAdd,
  mainTitle,
  type,
  file,
  refreshStats,
}) => {
  const [searchedValue, setSearchedValue] = useState("");
  const { allergies, medicines, diagnoses, vaccines } =
    useContext(ApplicationContext);
  const [list, setList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState(file);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [documentTitle, setDocumentTitle] = useState("");
  const [documentType, setDocumentType] = useState(documentTypes[0].value);
  const [documentDescription, setDocumentDescription] = useState("");
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  useEffect(() => {
    if (type) {
      switch (type) {
        case CATEGORIES_TYPES.allergies:
          setList(allergies);
          break;
        case CATEGORIES_TYPES.medicines:
          setList(medicines);
          break;
        case CATEGORIES_TYPES.diagnoses:
          setList(diagnoses);
          break;
        case CATEGORIES_TYPES.vaccines:
          setList(vaccines);
          break;
        default:
          setList([]);
      }
    }
  }, [type]);

  useEffect(() => {
    setSelectedFile(file);
  }, [file]);

  useEffect(() => {
    if (searchedValue && searchedValue.length > 1) {
      searchFromTheList();
    } else {
      setFilteredList([]);
    }
  }, [searchedValue]);

  const searchFromTheList = () => {
    setFilteredList(
      list.filter((item) => {
        return item?.description?.includes(searchedValue);
      })
    );
  };

  const saveDataToList = async () => {
    const result = await addNewItem(type, selectedDescription);
    if (result) {
      setShowAdd(false);
      refreshStats();
    }
  };
  const uploadImage = () => {
    if (selectedFile) {
      const fileName = `${new Date().getTime()}_${selectedFile.name}`;
      const imageRef = ref(storage, fileName);

      const uploadTask = uploadBytesResumable(imageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading image:", error.message);
          setUploadStatus("Upload failed");
        },
        async () => {
          // Upload completed successfully, get the download URL
          const userData = getSecondaryProfile();
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          addNewDocument({
            category: documentType,
            description: documentDescription,
            email: userData.email,
            extension: selectedFile.type,
            id: 0,
            name: selectedFile.name,
            title: documentTitle,
            url: downloadURL,
          });
          setUploadStatus("Upload successful. Image URL: " + downloadURL);
          setShowAdd(false);
          refreshStats();
        }
      );
    } else {
      alert("Please select an image");
    }
  };

  return (
    <Modal
      show={showAdd}
      size="xl"
      onHide={() => setShowAdd(false)}
      animation={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {`Select ${mainTitle || ""}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="addDocumentBodyContainer">
          <span className="labelField">File Name</span>
          <span className="searchField">{file?.name || ""}</span>
          <span className="labelField">Document Title</span>
          <input
            className="searchFieldDocument"
            placeholder="Search here..."
            value={documentTitle}
            onChange={({ target: { value } }) => {
              setDocumentTitle(value);
              if (value?.length != 0) {
                setShowValidationMsg(false);
              }
            }}
            onBlur={(e) => {
              if (documentTitle?.length != 0) {
                setShowValidationMsg(false);
              }
            }}
          />
          <div className="error-input-container">
            {showValidationMsg ? (
              <p className="form-error">{"Title is Required"}</p>
            ) : null}
          </div>
          <span className="labelField">Document Type</span>

          <select
            className="searchFieldDocument"
            value={documentType}
            onChange={({ target: { value } }) => setDocumentType(value)}
          >
            {documentTypes.length > 0 &&
              documentTypes.map(({ value, label }, index) => {
                return <option key={index} value={value}>{label}</option>;
              })}
          </select>

          <span className="labelField">Document Description (Optional)</span>
          <textarea
            className="searchFieldDocument"
            value={documentDescription}
            onChange={({ target: { value } }) => {
              setDocumentDescription(value);
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (documentTitle?.length == 0) {
              setShowValidationMsg(true);
            } else {
              uploadImage();
            }
          }}
        >
          ADD
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
