import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./style.scss";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { constants, selectedStripeKey } from "common";
import { Formik, Form } from "formik";
import { paymentSchema } from "common/schemas";
import Grid from "@mui/material/Grid";
import { InputField } from "components/input-field";
import { GenericButton } from "components/generic-button";
import { GrClose } from "react-icons/gr";
import { colors } from "common/theme";
import Stripe from "stripe";

export const PayWithStripe = ({ show, handleClose, setApiResponse }) => {
  const { placeholders } = constants;
  // const stripe = useStripe();
  const elements = useElements();
  const handleCardFieldChange = (event, name) => {
    if (event.error) {
      setStripeError((other) => ({ other, [name]: event.error.message }));
    } else {
      setStripeError((other) => ({ other, [name]: null }));
    }
  };
  const [stripeError, setStripeError] = useState({
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });
  const CreateCardStripe = async (cardName, loadingFalse) => {
    const stripe = new Stripe(selectedStripeKey);

    // if (!stripe || !elements) {
    //   return;
    // }
    // const cardNumber = elements.getElement(CardNumberElement);
    // const cardExpiry = elements.getElement(CardExpiryElement);
    // const cardCvc = elements.getElement(CardCvcElement);
    // const isValidCard = cardNumber._empty || cardNumber._invalid;
    // const isValidExpiry = cardExpiry._empty || cardExpiry._invalid;
    // const isValidCvc = cardCvc._empty || cardCvc._invalid;

    // if (isValidCard || isValidExpiry || isValidCvc || cardName == "") {
    //   setStripeError((other) => ({
    //     other,
    //     cardName: cardName == "" ? constants.errorMessages.CardName : null,
    //     cardNumber: isValidCard && constants.errorMessages.cardNumber,
    //     cardExpiry: isValidExpiry && constants.errorMessages.cardExpiry,
    //     cardCvc: isValidCvc && constants.errorMessages.cardCvv,
    //   }));
    //   return;
    // }

    // const { error, token } = await stripe.createToken(
    //   elements.getElement(CardNumberElement)
    // );

    // if (error) {
    //   alert(error.message || "");
    //   loadingFalse();
    // } else {
    //   const paymentMethodResult = await stripe.createPaymentMethod({
    //     type: "card",
    //     card: {
    //       token: token.id,
    //     },
    //   });
    //   console.log("paymentMethodResult", paymentMethodResult);
    // if (paymentMethod) {
    //   const productID = "prod_NpENWtewvzz3q9";
    //   const priceID = "price_1N3Zv8AO3RwSsPFvI9Ge0wrY";

    //   const { error: subscriptionError } = await stripe.createSubscription({
    //     customer_email: "testBySher@example.com",
    //     items: [{ price: priceID }],
    //     payment_method: paymentMethod.id,
    //     expand: ["latest_invoice.payment_intent"],
    //   });

    //   if (subscriptionError) {
    //     console.log(subscriptionError.message);
    //   } else {
    //     console.log("Subscription successful!");
    //     // Handle success UI update here
    //   }
    // }

    // const {
    //   id,
    //   card: { last4, brand },
    // } = paymentMethod;

    // if (paymentMethod) {
    //   loadingFalse();
    // } else if (error) {
    //   alert(error.message || "");
    // }
    try {
      const params: Stripe.Checkout.SessionCreateParams = {
        // submit_type: 'pay',
        mode: "subscription",
        payment_method_types: ["card"],
        line_items: [
          {
            price_data: {
              currency: "eur",
              product_data: {
                name: "Personal Safety",
              },
              // unit_amount_decimal: "1178",
              unit_amount_decimal: "995",
              recurring: {
                // Set up recurring payment
                interval: "year",
                interval_count: 1,
              },
            },
            adjustable_quantity: {
              enabled: false,
            },
            quantity: 1,
          },
        ],
        allow_promotion_codes: true,
        success_url: `${constants.siteBaseURL}/sign-up?session_id={'8712576tg12yeg78'}`,
        cancel_url: `${constants.siteBaseURL}/setup/subscription`,
      };

      //   create checkout session
      const session = await stripe.checkout.sessions.create(params);
      if (session?.url) {
        window.location.assign(session?.url);
      }
    } catch (err) {
      console.log("Error creating checkout session");
      // res.status(500).json(err.message);
    }
  };

  return (
    <Modal centered show={show} onHide={handleClose}>
      <Modal.Body>
        {/* <div className="add-payment-popup-wrapper">
          <div className="top-add-payment-container">
            <span>{""} </span>
            <GrClose
              className="add-payment-close-btn"
              onClick={handleClose}
              size={20}
              color={colors.primary}
            />
          </div>
          <Formik
            initialValues={{
              cardHolderName: "",
            }}
            validateOnMount={true}
            onSubmit={(values, formikActions) => {
              CreateCardStripe(values.cardHolderName, () =>
                formikActions.setSubmitting(false)
              );
            }}
            validationSchema={paymentSchema}
            enableReinitialize
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              touched,
              errors,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <div>
                <div>
                  <div className="sign-up-form-heading">
                    <span>{"Card Holder Name"}</span>
                  </div>
                  <InputField
                    name={"cardHolderName"}
                    id={"cardHolderName"}
                    error={touched.cardHolderName && errors.cardHolderName}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.cardHolderName}
                    placeholder={placeholders.cardHolderName}
                  />
                  <>
                    <div className="sign-up-form-heading">
                      <span>{"Card Number"}</span>
                    </div>
                    <div className="card-number">
                      <CardNumberElement
                        onChange={(i) => console.log(i)}
                        required
                        options={{
                          showIcon: true,
                        }}
                      />
                    </div>
                    {stripeError?.cardNumber != "" && (
                      <span className="error-field">
                        {stripeError?.cardNumber}
                      </span>
                    )}
                  </>
                  <div className="expiry-cvc-container">
                    <div className="expiry-container">
                      <div className="sign-up-form-heading">
                        <span>{"Expiry"}</span>
                      </div>
                      <div className="card-expiry">
                        <CardExpiryElement
                          onChange={(i) =>
                            handleCardFieldChange(i, "cardExpiry")
                          }
                          required
                        />
                      </div>
                      {stripeError?.cardExpiry != "" && (
                        <span className="error-field">
                          {stripeError?.cardExpiry}
                        </span>
                      )}
                    </div>
                    <div className="cvv-container">
                      <div className="sign-up-form-heading">
                        <span>{"Cvv"}</span>
                      </div>
                      <div className="card-cvc">
                        <CardCvcElement
                          onChange={(i) => handleCardFieldChange(i, "cardCvc")}
                          required
                        />
                      </div>
                      {stripeError?.cardCvc != "" && (
                        <span className="error-field">
                          {stripeError?.cardCvc}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="save-btn-container">
                    <GenericButton
                      customStyle={"pay-now-btn-container"}
                      id="save-button"
                      buttonText={"Pay Now"}
                      onPress={handleSubmit}
                      loading={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div> */}
        <button onClick={() => CreateCardStripe("test")}>click</button>
      </Modal.Body>
    </Modal>
  );
};
