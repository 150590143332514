import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  EmergencyInputField,
  GenericButton,
  GenericModal,
  InputField,
  InsuranceInputField,
  PayWithStripe,
  PhoneInputField,
} from "components";
import { IoLogoWhatsapp } from "react-icons/io";
// import DatePicker from "react-datepicker";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { constants, dateFormat } from "common";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { converDateFormate } from "pages/sign-up";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { nationality } from "common/nationality";
import Autocomplete from "react-google-autocomplete";

export const ProfileRowSection = ({
  setIsValid,
  label,
  value,
  setValue,
  fieldType,
  options,
  onPlaceSelected,
  isRequired,
  isCellular
}) => {
  const [showValidationMsg, setShowValidationMsg] = useState(false);
  useEffect(() => {
    let isValid = false;
    if (fieldType === "input" && value?.trim() === "") {
      isValid = true;
      setShowValidationMsg(true);
    } else if (fieldType === "phone-num" && value?.trim() === "") {
      isValid = true;
      setShowValidationMsg(true);
    } else if (fieldType === "nationality" && value?.trim() === "") {
      isValid = true;
      setShowValidationMsg(true);
    } else if (fieldType === "select" && value?.trim() === "") {
      isValid = true;
      setShowValidationMsg(true);
    } else if (fieldType === "address" && value?.trim() === "") {
      isValid = true;
      setShowValidationMsg(true);
    } else {
      isValid = false;
      setShowValidationMsg(false);
    }
    if (isRequired)
      setIsValid(isValid);
  }, [fieldType, value]);

  return (
    <>
      <div className="rowContainer">
        <span className="labelSection">{label}</span>
        <div className="valueSection">
          {fieldType == "nationality" && (
            <div className="nationality-container">
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">
                    {value || "Nationality"}
                  </InputLabel> */}
                  <Select
                    sx={{
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label="Select a nationality"
                    onChange={({ target: { value } }) => {
                      setValue(value);
                    }}
                  >
                    {nationality.map((nationality, index) => (
                      <MenuItem key={index} value={nationality}>
                        {nationality}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          )}
          {fieldType == "input" && (
            <input
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                textAlign: "center",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                outline: 'none'
              }}
              value={value}
              onChange={({ target: { value } }) => setValue(value)}
            />
          )}
          {fieldType == "phone-num" && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
              <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <div className="phone-dropdown">
                  <PhoneInputField
                    value={value}
                    placeholder={`X (XXX) XXXX-XXXX`}
                    onChange={(value) => {
                      setValue(value);
                    }}
                  />
                </div>
              </div>
              {
                isCellular && value != '' && <a
                  style={{ cursor: 'pointer', width: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  href={`tel:${value}`}>
                  <IoLogoWhatsapp color="#25D366" />
                </a>
              }
            </div>
          )}
          {fieldType == "calendar" && (
            <div
              className="manage-account-datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "DatePicker"]}>
                  <DatePicker
                    placeholderText="Date of Birth"
                    maxDate={dayjs(new Date())}
                    value={dayjs(value) || ""}
                    onChange={(e) => {
                      let date = converDateFormate(e?.$d);
                      setValue(date);
                    }}
                    format="DD/MM/YYYY"
                    sx={{
                      boxShadow: "none",
                      outline: 'none',
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      ".MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd css-1uvydh2": {
                        backgroundColor: 'white',
                        textAlign: 'center'
                      },
                      ".MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd css-1bn53lx": {
                        backgroundColor: 'white'
                      }
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          )}
          {fieldType == `select` && (
            <select
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{
                width: "100%",
                height: "100%",
                border: "none",
                textAlign: "center",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                outline: 'none',
                // border: '1px solid red',
                marginRight: '10px'
              }}
              value={value}
              type="select"
              defaultValue={value}
              onChange={({ target: { value } }) => setValue(value)}
            >
              {options &&
                options.map(({ label, value }, index) => (
                  <option key={index} value={value}>
                    {label}
                  </option>
                ))}
            </select>
          )}
          {fieldType == `readOnly` && value}
          {fieldType == 'address' && <Autocomplete
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              textAlign: "center",
              borderTopRightRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
            apiKey={constants.googleAPIKey}
            defaultValue={value}
            onChange={({ target: { value } }) => setValue(value)}
            onPlaceSelected={(data) => onPlaceSelected(data)}
          />}
        </div>
      </div>

      <div className="error-input-container">
        {showValidationMsg && isRequired ? (
          <p className="form-error">{`${label} is Required`}</p>
        ) : null}
      </div>
    </>
  );
};
