import React, { useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router";
import logo from "logo.svg";
import { routePaths } from "routes";
import { clearStorage } from "utilities";
import { images } from "common";

export const Header = ({ navMenuList }) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false)

  return (
    <div style={{display: 'flex', flexDirection: 'row', width: '100%', paddingLeft: '20px', paddingRight: '20px', alignItems: 'center', width: '100%', maxWidth: 1980, margin: 'auto', justifyContent: 'space-between', gap: 60}}>
         <img src={images.wmcLogo} style={{ width: '140px', borderRadius: 0 }} />
         <div className="hamberger" onClick={()=> setOpen(val => !val)}>
         <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
            <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
            <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
            <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round"/>
          </svg>
         </div>
      <div className={`headerContainer ${open ? 'open' : 'closed'}`}>
     
        {navMenuList &&
          navMenuList.length > 0 &&
          navMenuList.map((item, index) => {
            return (
              <span
                key={index}
                className={
                  item.isSelected ? "navSelectedItemTitle" : "navItemTitle"
                }
                onClick={() => {
                  navigate(item.navigationScreen);
                  setOpen(false)
                }}
              >
                {item.name}
              </span>
            );
          })}
        <span
          onClick={() => {
            clearStorage()
            navigate({
              pathname: routePaths.signIn,
            })
            setOpen(false)
          }}
          className="navLogoutItemTitle"
        >
          Log Out
        </span>
      </div>
    </div>
  );
};
