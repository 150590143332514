import React, { Fragment } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { BaseRoutes } from "routes";
import "firebase/firestore";
import { ContextProvider } from "shared";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import ErrorBoundary from "ErrorrBoundary";
import { constants, selectedStripeKey } from "common";

function App() {
  const stripePromise = loadStripe(selectedStripeKey);
  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
  };

  const renderLoadingModal = () => {
    return (
      <Modal
        size="sm"
        show={false}
        centered
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body>
          <Spinner color="grey" animation="grow" />
          <span>Loading</span>
        </Modal.Body>
      </Modal>
    );
  };
  const rootElement = document.getElementById("root");

  return (
      <Elements stripe={stripePromise} options={options}>
        <ErrorBoundary>
          <ContextProvider>
            <Router>
              <Fragment>
                <BaseRoutes />
              </Fragment>
            </Router>

            {renderLoadingModal()}
          </ContextProvider>
        </ErrorBoundary>
      </Elements>
  );
}

export default App;
